import React, { useState, useEffect } from 'react'
import { IonRow, IonCol, IonCard, IonCardContent, IonCardHeader, IonList, IonItem, isPlatform } from '@ionic/react'
import content from 'util/content'

import styles from './ResearchProgramCard.module.scss'
import ResearchProgramCardDetail from './ResearchProgramCardDetail'
import ResearchProgramCardListItem from './ResearchProgramCardListItem'
import services from 'util/services'

const ResearchProgramCard = (props) => {
  const { pet } = props
  const desktop = isPlatform('desktop')

  const [researchPrograms, setResearchPrograms] = useState([])

  useEffect(async () => {
    const programs = await services.getResearchPrograms()
    setResearchPrograms(programs)
  }, [])

  const [activeProgram, setActiveProgram] = useState(desktop ? 0 : null)
  const completedPrograms = researchPrograms.filter(program => pet.researchPrograms?.find(petProg => petProg.id === program.id)?.complete)
  const availablePrograms = researchPrograms.filter(program => !completedPrograms.includes(program))
  const allPrograms = [...availablePrograms, ...completedPrograms]

  const onClickHandler = (index) => {
    if (activeProgram === index) {
      setActiveProgram(null)
    } else {
      setActiveProgram(index)
    }
  }

  return (
    <IonCard className={`${styles.tab_card} card`}>
      <IonRow>
        <IonCol size-lg="4" size-md="12" size="12">
          <IonCardHeader>
            <IonList>
              <div className="ion-margin-vertical">
                <IonItem lines="none">
                  <h6 className="Running_head_2 text_dark_grey">{content.get('RESEARCHPROGRAMS.GENERAL.AVAILABLE_LABEL')}</h6>
                </IonItem>
                { availablePrograms.map((program, index) => {
                  return (
                    <ResearchProgramCardListItem
                      key={`research-programs-${index}`}
                      index={index}
                      activeProgram={activeProgram}
                      program={program}
                      allPrograms={allPrograms}
                      pet={pet}
                      onClickHandler={() => {
                        onClickHandler(index)
                      }}
                    />)
                })
                }
              </div>

              { completedPrograms.length > 0 &&
              <div className="ion-margin-vertical">
                <IonItem lines="none">
                  <h6 className="Running_head_2 text_dark_grey">{content.get('RESEARCHPROGRAMS.GENERAL.COMPLETED_APPLICATIONS_LABEL')}</h6>
                </IonItem>

                {
                  completedPrograms.map((program, index) => {
                    const remappedIndex = availablePrograms.length + index
                    return (
                      <ResearchProgramCardListItem
                        key={`research-programs-${remappedIndex}`}
                        index={remappedIndex}
                        activeProgram={activeProgram}
                        program={program}
                        allPrograms={allPrograms}
                        pet={pet}
                        onClickHandler={() => {
                          onClickHandler(remappedIndex)
                        }}
                      />
                    )
                  })
                }

              </div>
              }
            </IonList>
          </IonCardHeader>
        </IonCol>
        {desktop &&
          <IonCol size-md="8" size="12">
            <IonCardContent>
              <ResearchProgramCardDetail currentProgram={allPrograms[activeProgram]} pet={pet}/>
            </IonCardContent>
          </IonCol>
        }
      </IonRow>
    </IonCard>
  )
}

export default ResearchProgramCard
