import React from 'react'
import { withIonLifeCycle, IonRow, IonCol } from '@ionic/react'
import { connect } from 'react-redux'
import content from 'util/content'

import RegisterForm from 'components/basepaws/account/RegisterForm'
import GeneralPage from 'components/basepaws/general/GeneralPage/GeneralPage'
import contentEditableAware from 'components/basepaws/utils/hooks/contentEditableAware'
import services from 'util/services'

class AccountRegister extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      editableContent: contentEditableAware()
    }
  }

  async load () {
    const user = await services.user()
    if (user && user.id) {
      if (!user.roles.find((i) => i.name === 'admin') || !this.state.editableContent) {
        if (this.props.user == null || this.props.user?.id === '') this.props.history.push('/login')
        else this.props.history.push('/profile')
      }
    }
  }

  async ionViewWillEnter () {
    if (!this.props.user?.id) {
      await this.load()
    } else {
      if (!this.props.user.roles.find((i) => i.name === 'admin') || !this.state.editableContent) {
        this.props.history.push('/profile')
      }
    }
  }

  render () {
    const user = this.props.user
    return (
        <GeneralPage
          title={content.get('REGISTER.FORM.TITLE')}
          headerStyle='center'
          titleClass="Display_4 text_sad_purple ion-margin-top ion-padding-bottom ion-text-center"
          user={user}
        >
          <IonRow className="ion-justify-content-center">
            <IonCol size-lg="4" size-md="6" size="11">
              <RegisterForm/>
            </IonCol>
          </IonRow>
        </GeneralPage>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.userInReducer.user,
    organizationActive: state.userInReducer.organizationActive,
    organization: state.userInReducer.organizationActiveData
  }
}

export default connect(mapStateToProps, null)(withIonLifeCycle(AccountRegister))
