import React, { useState } from 'react'
import { IonRow, IonCol, IonIcon, IonButton } from '@ionic/react'
import { checkmarkSharp, closeSharp, chevronDownSharp, chevronUpSharp } from 'ionicons/icons'
import { useSelector } from 'react-redux'
import content from 'util/content'
import Accordion from 'components/basepaws/utils/Accordion'
import styles from './ResearchProgramCard.module.scss'
import contentEditableAware from 'components/basepaws/utils/hooks/contentEditableAware'

const ResearchProgramCardDetail = (props) => {
  const { currentProgram, pet } = props
  const [showDetails, setShowDetails] = useState(false)
  const editableContent = contentEditableAware()
  const user = useSelector(state => state.userInReducer.user)

  const InformationBlock = ({ currentProgram }) => {
    return <>
      <IonRow>
        <IonCol size-md="11">
          { editableContent
            ? <p className="Body_1 text_black mb-2 mt-0" >
              {content.get(`RESEARCHPROGRAMS.${currentProgram?.contentKey}.DETAILS`)}
            </p>
            : <p className="Body_1 text_black mb-2 mt-0"
                dangerouslySetInnerHTML={ { __html: content.get(`RESEARCHPROGRAMS.${currentProgram?.contentKey}.DETAILS`) }}
              >
              </p>
          }
        </IonCol>
      </IonRow>
      <IonRow className={`${styles.tab_card_content_criteria}`}>
        <IonCol size-md="4" size="12">
          <div className="d-flex ion-align-items-center">
            <IonIcon color="success" icon={checkmarkSharp} slot="start" className="mr-2" />
            <p className="Body_2_bold text_sad_purple">{content.get('RESEARCHPROGRAMS.GENERAL.INCLUSION_PROGRAM_LABEL')}</p>
          </div>
        </IonCol>
        <IonCol size-md="8" size="12">
          <p className="Body_2 text_grey">{content.get(`RESEARCHPROGRAMS.${currentProgram?.contentKey}.INCLUSION_CRITERIA`)}</p>
        </IonCol>
      </IonRow>
      <IonRow className={`${styles.tab_card_content_criteria}`}>
        <IonCol size-md="4" size="12">
          <div className="d-flex ion-align-items-center">
            <IonIcon color="danger" icon={closeSharp} slot="start" className="mr-2" />
            <p className="Body_2_bold text_sad_purple">{content.get('RESEARCHPROGRAMS.GENERAL.EXCLUSION_PROGRAM_LABEL')}</p>
          </div>
        </IonCol>
        <IonCol size-md="8" size="12">
          <p className="Body_2 text_grey">{content.get(`RESEARCHPROGRAMS.${currentProgram?.contentKey}.EXCLUSION_CRITERIA`)}</p>
        </IonCol>
      </IonRow>
    </>
  }

  const notCompleted = (
    <>
      <IonRow>
        <h3 className="Heading_2 text_sad_purple mb-2">{content.get(`RESEARCHPROGRAMS.${currentProgram?.contentKey}.TITLE`)}</h3>
      </IonRow>
      <InformationBlock currentProgram={currentProgram} />
      {currentProgram?.requireDoc &&
      <IonRow className="ion-margin-top">
        <IonCol size="12">
          <div className="alert_message bg_grey text_dark_grey no-margin w-100">
            <p className="Body_2 text_sad_purple">{content.get('RESEARCHPROGRAMS.GENERAL.REQUIRED_DOC_TEXT')}</p>
          </div>
        </IonCol>
      </IonRow>
      }
      <IonRow className={`${styles.tab_card_not_completed_footer}`}>
        <p className="Body_1_bold text_sad_purple">{content.get('RESEARCHPROGRAMS.GENERAL.PRE_CTA_TEXT', { name: pet.name })}</p>
        <IonButton
          mode="md"
          color="primary"
          fill="solid"
          shape="round"
          aria-label="Apply to research program"
          className="default_button"
          target='_blank'
          href={`${currentProgram?.link}#email=${user?.email}&source=account&animal=${pet?.handle}`}
        >
          {content.get('RESEARCHPROGRAMS.GENERAL.CTA_TITLE')}
        </IonButton>
      </IonRow>
    </>
  )

  const completed = (
    <IonRow>
      <IonCol size-md="12">
        <h3 className="Heading_2 text_sad_purple mb-2">{content.get(`RESEARCHPROGRAMS.${currentProgram?.contentKey}.TITLE`)}</h3>

        <Accordion
          className="view_detail_button"
          list={[currentProgram]}
          toggleFunction={() => setShowDetails(!showDetails)}
          toggleVar={showDetails}
          toggleIcons={{ iconOpen: chevronDownSharp, iconClose: chevronUpSharp }}
          toggleIconPosition='before'
          renderHeader={() => {
            return (
              <IonButton
                mode="md"
                shape="round"
                color="primary"
                fill="clear"
                aria-label="View details about research program"
                className="icon-start default_button load_more"
              >
                {content.get('RESEARCHPROGRAMS.GENERAL.VIEW_DETAILS_LABEL')}
              </IonButton>
            )
          }}
          renderPanel={() => <InformationBlock currentProgram={currentProgram} />}
        />

        <div className="alert_message bg_grey text_dark_grey no-margin w-100 mb-4">
          { editableContent
            ? <p className="Body_2 text_sad_purple" >
              {content.get('RESEARCHPROGRAMS.GENERAL.COMPLETED_APPLICATION_TEXT')}
            </p>
            : <p className="Body_2 text_sad_purple"
                dangerouslySetInnerHTML={ { __html: content.get('RESEARCHPROGRAMS.GENERAL.COMPLETED_APPLICATION_TEXT') }}
              >
              </p>
          }
        </div>
        <div className="mb-4">
          <span className='tags completed d-inline-flex ion-align-items-center'>
            <IonIcon color="light" slot="end" className="mr-2" icon={checkmarkSharp} />
            {content.get('MY_ORDERS.SURVEYCARD.TAG_COMPLETED')}
          </span>
        </div>
      </IonCol>
    </IonRow>
  )

  return (pet.researchPrograms?.find(petProg => petProg.id === currentProgram?.id)?.complete) ? completed : notCompleted
}

export default ResearchProgramCardDetail
