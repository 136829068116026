import React, { useContext, useState, useRef } from 'react'
import {
  IonContent,
  IonGrid,
  IonPage,
  useIonViewWillEnter,
  useIonViewDidEnter,
  useIonViewWillLeave
} from '@ionic/react'
import { useHistory, useLocation } from 'react-router-dom'

import UserConfirmationToaster from 'components/basepaws/general/UserConfirmationToaster'
import content from 'util/content'
import services from 'util/services.js'
import { confirmUserAction } from 'util/utils'
import { pagesThatDoNotNeedAuth } from 'util/constants'

import Navbar from '../Navbar/Navbar'
import Header from '../Header'
import Footer from '../Footer'

const GeneralPage = (props) => {
  const {
    user,
    header,
    actionStyle,
    headerStyle,
    title,
    actions,
    isNotFixed,
    children,
    pageClass,
    scrollCallback,
    scrollEvents,
    goBackUrl,
    HandlerStepContext
  } = props

  const userConfirmedToast = useRef(false)
  const history = useHistory()
  const location = useLocation()

  const load = async () => {
    const user = await services.user()

    if (!user || !user.id) {
      const currentPageDoesNotNeedAuth = pagesThatDoNotNeedAuth.includes(location.pathname)
      if (!currentPageDoesNotNeedAuth) {
        history.push('/login')
      }
    }
  }

  useIonViewWillEnter(async () => {
    if (!user?.id) {
      const query = location?.hash
      userConfirmedToast.current = await confirmUserAction(query)
      await load()
    }
  })

  let actualFormStep = null
  let setActualFormStep = null
  if (HandlerStepContext) {
    [actualFormStep, setActualFormStep] = useContext(HandlerStepContext)
  }

  const [topbarStatus, setTopbarStatus] = useState(
    content.get('NAVBAR.TOPBAR.ACTIVE') === 'true'
  )

  const topbarCheck = () => {
    if (
      topbarStatus &&
      !services.topbarCheck(content.get('NAVBAR.TOPBAR.TEXT'))
    ) {
      setTopbarStatus(false)
    }
    return <></>
  }

  const scrollToTop = () => {
    const size = document.getElementsByTagName('ion-toolbar').length
    for (let index = 0; index < size; index++) {
      document.getElementsByTagName('ion-toolbar')[index].scrollIntoView({
        block: 'start',
        behavior: 'instant'
      })
    }
  }

  useIonViewDidEnter(() => {
    scrollToTop()
  })

  useIonViewWillLeave(() => {
    scrollToTop()
  })

  return (
    <IonPage className={pageClass}>
      {userConfirmedToast.current ? <UserConfirmationToaster/> : null}
      {topbarCheck()}
      <Navbar
        user={user}
        mobileBackButton={actionStyle === 'left'}
        topbarToggle={setTopbarStatus}
        topbarStatus={topbarStatus}
        goBackUrl={goBackUrl}
      />

      <IonContent
        fullscreen
        id="content"
        scrollEvents={scrollEvents}
        onIonScroll={scrollCallback}
      >
        {header !== 'none' && HandlerStepContext
          ? (
          <HandlerStepContext.Provider
            value={[actualFormStep, setActualFormStep]}
          >
            <Header
              actionStyle={actionStyle}
              headerStyle={headerStyle}
              title={title}
              actions={actions}
              goBackUrl={goBackUrl}
              HandlerStepContext={HandlerStepContext}
            />
          </HandlerStepContext.Provider>
            )
          : (
          <Header
            actionStyle={actionStyle}
            headerStyle={headerStyle}
            title={title}
            actions={actions}
            goBackUrl={goBackUrl}
          />
            )}
        <IonGrid fixed={!isNotFixed}>
          <div className="content">{children}</div>
        </IonGrid>

        <Footer />
      </IonContent>
    </IonPage>
  )
}

export default GeneralPage
