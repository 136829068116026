import React, { useEffect, useState } from 'react'
import { IonItem, IonLabel, isPlatform, IonImg } from '@ionic/react'
import content from 'util/content'

import styles from './ResearchProgramCard.module.scss'
import ResearchProgramCardDetail from './ResearchProgramCardDetail'
import Accordion from 'components/basepaws/utils/Accordion'

const ResearchProgramCardListItem = (props) => {
  const { index, activeProgram, program, allPrograms, pet, onClickHandler } = props
  const [isActive, setIsActive] = useState(index === activeProgram)
  const desktop = isPlatform('desktop')
  const iconBasePath = '/assets/icon/research-program/'

  useEffect(() => {
    setIsActive(index === activeProgram)

    return () => {
      setIsActive(false)
    }
  }, [activeProgram, index])

  return (
  <div className={`${desktop ? '' : styles.accordion_list_item}`}>
    { desktop &&
    <IonItem onClick={onClickHandler} lines="none" className={`${activeProgram === index ? styles.tab_card_item_active : ''}`}>
      <IonImg src={`${iconBasePath}${program.contentKey.toLowerCase()}.svg`} className="mr-2"/>
      <IonLabel className="Body_1 text_grey">{content.get(`RESEARCHPROGRAMS.${program.contentKey}.SHORT_TITLE`)}</IonLabel>
    </IonItem>
    }

    { !desktop &&
      <Accordion
        className="research_program_accordion"
        list={[allPrograms]}
        toggleFunction={() => onClickHandler()}
        toggleVar={isActive}
        // toggleIcons={{ iconOpen: chevronDownSharp, iconClose: chevronUpSharp }}
        toggleIconPosition='before'
        renderHeader={() => {
          return (
            <IonItem lines="none" className={`${activeProgram === index ? styles.tab_card_item_active : ''}`}>
              <IonImg src={`${iconBasePath}${program.contentKey.toLowerCase()}.svg`} className="mr-2"/>
              <IonLabel className="Body_1 text_grey">{content.get(`RESEARCHPROGRAMS.${program.contentKey}.SHORT_TITLE`)}</IonLabel>
            </IonItem>
          )
        }}
        renderPanel={() => (
          <div className={`${styles.accordion_content}`}>
            <ResearchProgramCardDetail currentProgram={allPrograms[activeProgram]} pet={pet}/>
          </div>
        )}
      />
    }
  </div>
  )
}

export default ResearchProgramCardListItem
